
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { FulfillmentAuthorization, SourceSystemClaimData, DynamicFulfillmentState, SourceSystemIdentifier } from '@/store/dynamicfulfillment/types';
import { FETCH_SOURCE_SYSTEM_CLAIM } from '@/store/dynamicfulfillment/constants';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
import ClaimedEquipment from '@/components/FulfillmentAuthorization/ClaimedEquipment.vue';
import PhoneNumber from '@/components/common/PhoneNumber.vue';
import DataTable from '@/components/common/DataTable.vue';
import { UserAuthorizationProvider, userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import EventBus from '@/common/event.bus';
import MockClaimDataComponent from '@/components/FulfillmentAuthorization/MockClaimDataComponent.vue';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { VueJSONEditor, ClaimedEquipment, PhoneNumber, DataTable, MockClaimDataComponent } })
export default class ClaimDataComponent extends Vue {
  @Prop() authorization!: FulfillmentAuthorization;
  @State(namespace) private profile: DynamicFulfillmentState;
  @Action(FETCH_SOURCE_SYSTEM_CLAIM, { namespace }) private fetchSourceSystemClaim: (sourceSystemIdentifier: SourceSystemIdentifier) => Promise<SourceSystemClaimData>;

  sourceSystemClaimData: any = null;
  jsonEditorText: any = jsonToText;
  userAuthorizationProvider: UserAuthorizationProvider;
  editorKey: number = 0;

  async created() {
    this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    await this.getClaimData();
  }

  get claimStatus(): number {
    return this.profile.claimStatuses[this.authorization.claimData.claimSummary.status];
  }

  get sourceSystemIdentifier(): SourceSystemIdentifier {
    return {
      sourceSystem: this.authorization.source.toString(),
      claimNumber: this.authorization.claimData?.claimSummary?.id || '',
    };
  }

  async getClaimData() {
    try {
      const claim = await this.fetchSourceSystemClaim(this.sourceSystemIdentifier);
      this.sourceSystemClaimData = claim;
      this.editorKey++;
    } catch (error: any) {
      EventBus.$emit('e', `Fetch Source System Claim failed. ${error}`);
    }
  }

  claimDataToString(claimData: any): string {
    return JSON.stringify(claimData, null, 2);
  }

  hasPermission(features: string[]) {
    if (!this.userAuthorizationProvider) {
      this.userAuthorizationProvider = userAuthorizationProviderFactory(this.profile);
    }
    return this.userAuthorizationProvider.hasPermission(features);
  }
}
