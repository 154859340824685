
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import EventBus from '@/common/event.bus';
import {
  DynamicFulfillmentState,
  FulfillmentOption,
  FulfillmentOptionConfig,
  IPriceListConfig,
  PriceListConfig,
  InvoiceTaxStrategy,
  DeviceTypeIdLookupStrategy,
  ProgramConfig,
} from '@/store/dynamicfulfillment/types';
import {
  FETCH_PRICE_LIST_CONFIGS,
  FETCH_PROGRAM_CONFIGS,
  INSERT_PRICE_LIST_CONFIG,
  UPDATE_PRICE_LIST_CONFIG,
  GET_FULL_PRICE_LIST_CONFIGS,
  FETCH_FULFILLMENT_OPTION_CONFIGS,
  UPLOAD_PRICE_LIST_ITEMS,
  FETCH_PRICE_LIST_CONFIG,
} from '@/store/dynamicfulfillment/constants';
import { Action, Getter, State } from 'vuex-class';
import { buildErrorMessage } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';
import PerLineTextAreaControl from '@/components/common/PerLineTextAreaControl.vue';
import { arraysEqual } from '@/common/utilities';
import { validateFileType, formatFileName, uploadFile } from '@/common/file.helpers';

@Component({ components: { ViewContent, PerLineTextAreaControl } })
export default class PriceListConfigCreateEditComponent<T> extends Vue {
  @Prop({ required: true }) private priceListConfig: IPriceListConfig<T>;
  @Prop() private documentType: string;
  @Prop() private apiRoute: string;
  @Prop() private route: string;
  @Action(FETCH_PRICE_LIST_CONFIGS, { namespace })
  private fetchPriceListConfigs: any;
  @Action(FETCH_FULFILLMENT_OPTION_CONFIGS, { namespace })
  private fetchFulfillmentOptionConfigs: any;
  @Action(FETCH_PROGRAM_CONFIGS, { namespace })
  private fetchProgramConfigs: any;
  @Action(INSERT_PRICE_LIST_CONFIG, { namespace })
  private insertPriceListConfig: any;
  @Action(UPDATE_PRICE_LIST_CONFIG, { namespace })
  private updatePriceListConfig: any;
  @Action(UPLOAD_PRICE_LIST_ITEMS, { namespace }) 
  private uploadPriceListItems: ((request: { payload: any; configId: string }) => Promise<void>);
  @Getter(GET_FULL_PRICE_LIST_CONFIGS, { namespace })
  private getFullPriceListConfigs: any;
  @Action(FETCH_PRICE_LIST_CONFIG, { namespace }) 
  private fetchPriceListConfig: ((request: { id: string; route: string, forceFetch: boolean }) => Promise<IPriceListConfig<T>>);
  @State(namespace) private profile!: DynamicFulfillmentState;

  private priceListConfigs: IPriceListConfig<T>[] = [];
  private programConfigs: ProgramConfig[] = [];
  private fulfillmentOptionConfigs: FulfillmentOptionConfig[] = [];
  private file: File = null;
  private isValidState: boolean = true;
  private isValidFileType: boolean = true;
  private isLoading: boolean = false;

  private get isNew() {
    return this.priceListConfig !== null && this.priceListConfig._etag === null;
  }

  private get pageTitle(): string {
    return this.priceListConfig && !this.isNew
      ? `${this.priceListConfigName}`
      : 'Create Price List Config';
  }

  private get priceListConfigName(): string {
    return this.priceListConfig
      ? `${this.priceListConfig.vendor} | ${
          FulfillmentOption[this.priceListConfig.fulfillmentOption]
        }`
      : '';
  }

  private get allowedFulfillmentOptions() {
    return Object.keys(FulfillmentOption)
      .filter(key => isNaN(Number(key)))
      .sort((a, b) => a.localeCompare(b))
      .map(key => FulfillmentOption[key as keyof typeof FulfillmentOption]);
  }

  private get allowedDeviceTypeIdLookupStrategy() {
    return Object.keys(DeviceTypeIdLookupStrategy);
  }

  private get allowedinvoiceTaxStrategy() {
    return Object.keys(InvoiceTaxStrategy);
  }

  private get fulfillmentOptionState() {
    return this.priceListConfig.fulfillmentOption != null && this.priceListConfig.fulfillmentOption !== FulfillmentOption.None;
  }

  private get deviceTypeIdLookupStrategyState() {
    return this.priceListConfig.deviceTypeIdLookupStrategy != null;
  }

  private get invoiceTaxStrategyState() {
    return this.priceListConfig.invoiceTaxStrategy != null;
  }

  private get vendorState() {
    return this.priceListConfig.vendor !== null;
  }

  private get priceListConfigState() {
    return this.checkFormValidity();
  }

  private get configState() {
    return (
      this.fulfillmentOptionState &&
      this.vendorState &&
      this.priceListConfigState &&
      this.deviceTypeIdLookupStrategyState &&
      this.invoiceTaxStrategyState &&
      this.validation
    );
  }

  private get invalidPriceListConfigFeedback() {
    if (this.priceListConfig.fulfillmentOption && this.priceListConfig.vendor) {
      return `There is already a price list configured with the provided options`;
    }
  }

  public async handleSubmit() {
    if (!this.checkFormValidity()) {
      return;
    }

    const requestObject = {
      id: this.priceListConfig.id,
      fulfillmentOption: this.priceListConfig.fulfillmentOption,
      vendor: this.priceListConfig.vendor,
      itemsBlobName: this.priceListConfig.itemsBlobName ? this.priceListConfig.itemsBlobName : (null as any),
      programIds: this.priceListConfig.programIds,
      serviceProviders:
        this.priceListConfig.serviceProviders &&
        this.priceListConfig.serviceProviders.length >= 1
          ? this.priceListConfig.serviceProviders
          : [],
      deviceTypeIdLookupStrategy: this.priceListConfig.deviceTypeIdLookupStrategy,
      invoiceTaxStrategy: this.priceListConfig.invoiceTaxStrategy,
      documentType: this.documentType
    };
    
    this.isLoading = true;

    if (this.isNew) {
      await this.insertPriceListConfig({
        payload: requestObject,
        route: this.apiRoute,
      }).then(async (response: PriceListConfig) => {
        await this.upload(this.file, response.id)
        .then(async () => {
          EventBus.$emit('s', 'Price list items upload submitted successfully. Processing may take a few minutes. Please refresh the price list upload status page for current upload status.');
          await this.fetchPriceListConfig({ id: response.id, route: this.apiRoute, forceFetch: true });
        })
        .catch((error: any) => {
          this.isValidState = false;
          const message = buildErrorMessage(error);
          EventBus.$emit('e', message);
        });
      });
    } else {
      await this.updatePriceListConfig({
        config: requestObject,
        apiRoute: this.apiRoute,
      })
      .catch((error: any) => {
        this.isValidState = false;
        const message = buildErrorMessage(error);
        EventBus.$emit('e', message);
      });
    }
          
    this.isLoading = false;
    EventBus.$emit('s', 'Price List Config saved successfully.');
    this.$router.push({ name: `${this.route + 'PriceListConfig-Index'}` });
  }

  public async mounted() {
    await this.fetchProgramConfigs();
    await this.fetchFulfillmentOptionConfigs();
    this.programConfigs = this.profile.programConfigs;
    this.fulfillmentOptionConfigs = this.profile.fulfillmentOptionConfigs;
    await this.fetchPriceListConfigs({route: this.apiRoute, forceFetch: false});
    this.priceListConfigs = this.getFullPriceListConfigs(this.apiRoute);
  }

  private fulfillmentOptionLabel(option: any) {
    if (isNaN(option)) {
      return option;
    } else {
      return FulfillmentOption[option];
    }
  }

  private formatter(value: string) {
    return value.replace(',', '').replace("'", '');
  }

  private updateServiceProviders(updatedConfig: any) {
    this.priceListConfig.serviceProviders = updatedConfig;
  }

  private checkFormValidity(): boolean {
    if (this.fulfillmentOptionState && this.vendorState) {
      let existingPriceListConfigs: IPriceListConfig<T>[] = null;
      if (this.isNew) {
        existingPriceListConfigs = this.priceListConfigs.filter(
          (plc) => plc.fulfillmentOption === this.priceListConfig.fulfillmentOption &&
            plc.vendor === this.priceListConfig.vendor &&
            (arraysEqual(
              plc.programIds,
              this.priceListConfig.programIds,
            ) ||
              plc.programIds.filter((programId) =>
                this.priceListConfig.programIds.includes(programId),
              ).length >= 1) &&
            (arraysEqual(
              plc.serviceProviders,
              this.priceListConfig.serviceProviders,
            ) ||
              plc.serviceProviders.filter((provider) =>
                this.priceListConfig.serviceProviders.includes(provider),
              ).length >= 1),
        );
      } else {
        existingPriceListConfigs = this.priceListConfigs.filter(
          (plc) =>plc.fulfillmentOption === this.priceListConfig.fulfillmentOption &&
            plc.vendor === this.priceListConfig.vendor &&
            plc.id !== this.priceListConfig.id &&
            (arraysEqual(
              plc.programIds,
              this.priceListConfig.programIds,
            ) ||
              plc.programIds.filter((programId) =>
                this.priceListConfig.programIds.includes(programId),
              ).length >= 1) &&
            (arraysEqual(
              plc.serviceProviders,
              this.priceListConfig.serviceProviders,
            ) ||
              plc.serviceProviders.filter((provider) =>
                this.priceListConfig.serviceProviders.includes(provider),
              ).length >= 1),
        );
      }
      return existingPriceListConfigs.length === 0;
    }
    return true;
  }

  get vendors() {
    if (this.priceListConfig.fulfillmentOption === null) {
      return [];
    }

    const fulfillmentOptionConfig = this.fulfillmentOptionConfigs.find((v: FulfillmentOptionConfig) => v.fulfillmentOption === this.priceListConfig.fulfillmentOption);

    if (fulfillmentOptionConfig && fulfillmentOptionConfig.vendors) {
      return fulfillmentOptionConfig.vendors.map(vendor => vendor.name);
    }

    return [];
  }

  get validation() {
    if (this.file) {
      this.isValidFileType = validateFileType(this.file, ['csv', 'xlsx']);
    }

    return this.isValidFileType && this.isValidState;
  }

  private formatFileName(files: File[]) {
    return formatFileName(files[0]);
  }

  private async fileChangeEvent(files: File[]) {
    this.isValidState = true;
    this.isValidFileType = true;
  }

  private get invalidFileUploadFeedBack() {
    if (this.isValidFileType === false) {
      return 'Invalid file. Select ".csv or .xlsx" file';
    }
    if (this.isValidState === false) {
      return 'File contains invalid data.';
    }
  }
  
  private async upload(file: File, id: string) {
    EventBus.$emit('i', 'Please wait. Uploading Price List Items...');
    
    const uploadFunction = async (payload: any) => {
      await this.uploadItems(file, id);
    };

    try {
      await uploadFile(file, uploadFunction);
    } catch (error: any) {
      this.isValidState = false;
      const message = buildErrorMessage(error);
      EventBus.$emit('e', message);
    }
  }

  private async uploadItems(file: File, id: string) {
    await await this.uploadPriceListItems({payload: file, configId: id})
    .catch((error: any) => {
      this.isValidState = false;
      const message = buildErrorMessage(error);
      EventBus.$emit('e', message);
    });
  }

  private routeToFileUpload() {
    const fileUpload: any = {
      name: `${this.route + 'PriceListConfig-Items-Upload'}`,
      params: {
        item: this.priceListConfig,
      },
    };
    this.$router.push(fileUpload);
  }
}
